import { useLocation } from '@reach/router';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List, Sort } from 'shared/components';
import { useLocalStorage } from 'shared/hooks';
import { applySort } from 'shared/utils';
import {
  ProposalInvoicesLock,
  ProposalListError,
  ProposalListItem,
} from '../components';
import {
  useProposalsErrorSelector,
  useProposalsLoadingSelector,
  useProposalsWithInvoiceSelector,
} from '../state';

export const INVOICES_SECTION_PASSWORD = 'sigurno_je_sigurno316';

export const InvoicesSection: React.FC = () => {
  const location = useLocation();
  const error = useProposalsErrorSelector();
  const loading = useProposalsLoadingSelector();
  const invoices = useProposalsWithInvoiceSelector();

  const [sortOption, setSortOption] = useState<SortOption>('newest');
  const [invoicesPassword, setInvoicesPassword] = useLocalStorage<string>(
    'invoicesPagePassword',
  );
  const [isAuthenticated, setIsAuthenticated] = useState(
    invoicesPassword === INVOICES_SECTION_PASSWORD,
  );
  const sortedInvoices = useMemo(
    () => applySort(sortOption, invoices),
    [invoices, sortOption],
  );

  useEffect(() => {
    setSortOption('newest');
  }, [location.pathname]);

  return (
    <>
      <header
        className="dashboard__section__header__title text--xs t-bold"
        data-cy="dashboard-overview-title"
      >
        <FormattedMessage id="invoices.title" />
      </header>
      <div className="dashboard__section__mobile__actions">
        <header className="text--xs t-bold" data-cy="invoices-title">
          <FormattedMessage
            id="invoices.mobile-title"
            values={{
              length: sortedInvoices?.length,
            }}
          />
        </header>
        {isAuthenticated && (
          <div className="f f--align-center">
            <Sort sortOption={sortOption} onSetSortOption={setSortOption} />
          </div>
        )}
      </div>
      {!isAuthenticated ? (
        <ProposalInvoicesLock
          onSetInvoicesPassword={setInvoicesPassword}
          onSetIsAuthenticated={setIsAuthenticated}
        />
      ) : (
        <>
          {Boolean(sortedInvoices?.length) && (
            <div className="dashboard__section__actions">
              <span className="t-text-2 text--sm">
                <FormattedMessage
                  id="invoices.length"
                  values={{
                    length: sortedInvoices?.length,
                  }}
                />
              </span>
              <Sort
                sortOption={sortOption}
                onSetSortOption={setSortOption}
                btnSelector="sort-proposals"
              />
            </div>
          )}
          <List
            documents={invoices}
            results={sortedInvoices}
            isLoading={loading}
            error={error}
            errorComponent={ProposalListError}
            itemComponent={ProposalListItem}
            className="dashboard__section__list__container"
            errorClassName="dashboard__section__error__container"
            loadingType="list"
          />
        </>
      )}
    </>
  );
};
