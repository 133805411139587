import { authorizationSelector } from 'modules/authorization';
import React from 'react';
import { useSelector } from 'react-redux';
import { PreviewButton } from '../../shared-buttons';
import { CopyToClipboardButton } from '../../shared-buttons/copy-to-clipboard-button';
import { UnpublishButton } from '../../shared-buttons/unpublish-button';
import { ActionDrawerClassNames } from '../ActionDrawerClassNames';
import { DeleteButton } from '../delete-button';
import { DuplicateAsTemplateButton } from '../duplicate-as-template-button';
import { DuplicateButton } from '../duplicate-button';
import { ProposalToInvoiceButton } from '../proposal-to-invoice-button';
import { useLocalStorage } from 'shared/hooks';
import { INVOICES_SECTION_PASSWORD } from 'modules/proposals/views/InvoicesSection';

interface Props extends ActionDrawerClassNames {
  proposal: Proposal;
  isItemActions?: boolean;
  isEditorActions?: boolean;
  isOverview?: boolean;
}

export const PublishedDrawerButtons: React.FC<Props> = ({
  duplicateAsTemplateClassName,
  duplicateClassName,
  unpublishClassName,
  deleteClassName,
  proposal,
  isItemActions,
  isEditorActions,
  isOverview,
}) => {
  const { adminRole } = useSelector(authorizationSelector.getState);
  const [invoicesPassword] = useLocalStorage<string>('invoicesPagePassword');

  return (
    <>
      {!proposal?.isInvoice &&
        adminRole === 'superAdmin' &&
        invoicesPassword === INVOICES_SECTION_PASSWORD && (
          <ProposalToInvoiceButton
            className={duplicateAsTemplateClassName}
            proposal={proposal}
            btnSelector="published-duplicate-as-template-btn"
          />
        )}
      {isOverview && (
        <>
          <CopyToClipboardButton
            className="actionbar__drawer__btn"
            btnSelector="copy-to-clipboard-btn"
            isDrawerBtn
          />
          <PreviewButton
            className="actionbar__drawer__btn"
            btnSelector="preview-proposal-btn"
            isDrawerBtn
          />
        </>
      )}
      <DuplicateButton
        className={duplicateClassName}
        proposal={proposal}
        btnSelector="published-page-duplicate-btn"
      />
      {!proposal?.isInvoice && (
        <DuplicateAsTemplateButton
          className={duplicateAsTemplateClassName}
          proposal={proposal}
          btnSelector="published-duplicate-as-template-btn"
        />
      )}
      {isEditorActions && !proposal?.isInvoice && (
        <div className="d--mobile--only">
          <UnpublishButton className={`${unpublishClassName}`} isOverview />
        </div>
      )}
      {(isItemActions || isOverview) && !proposal?.isInvoice && (
        <UnpublishButton
          className={unpublishClassName}
          isOverview
          btnSelector="more-option-unpublish-btn"
        />
      )}
      {isEditorActions && !proposal?.isInvoice && (
        <div className="d--desktop--only">
          <UnpublishButton
            className={unpublishClassName}
            isOverview
            btnSelector="unpublish-btn"
          />
        </div>
      )}
      {proposal?.isInvoice && adminRole === 'superAdmin' && (
        <DeleteButton className={deleteClassName} />
      )}
    </>
  );
};
