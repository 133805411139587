import { GroupBase, StylesConfig } from 'react-select';

export type OptionType = {
  value: AcceptanceStatus;
  label: React.ReactNode;
};

type CustomStyles = StylesConfig<OptionType, false, GroupBase<OptionType>>;

export function getSelectStyles(): CustomStyles {
  return {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? 'hsla(0, 0%, 18%, 0.04)'
        : 'hsl(0, 0%, 100%)',
      ':hover': {
        borderColor: state.isFocused
          ? 'hsl(262, 100%, 61%)'
          : 'hsl(0, 0%, 85%)',
        transition: 'border-color 0.2s ease-out',
      },
      borderColor: state.isFocused ? 'hsl(262, 100%, 61%)' : 'hsl(0, 0%, 85%)',
      boxShadow: 'none',
      cursor: 'pointer',
      minHeight: '24px',
      minWidth: '104px',
      width: 'fit-content',
      height: 'fit-content',
      transition: 'all 0.2s ease-out',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'hsl(0, 0%, 20%)',
      width: 'fit-content',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'hsl(0, 0%, 95%)',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'hsl(0, 0%, 40%)',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'hsl(0, 0%, 100%)',
      minWidth: '148px',
      borderRadius: '6px',
      border: '1px solid #d9d9d9 !important',
      boxShadow: '0px 12px 24px 0px rgba(0, 0, 0, 0.05)',
    }),
    menuList: (provided, { options }) => ({
      ...provided,
      ...(options?.length > 10
        ? { maxHeight: 'calc(8 * (1em + 24px))', overflowY: 'auto' }
        : {}),
      paddingTop: 0,
      paddingBottom: 0,
      border: '0 solid #d9d9d9 !important',
    }),
    option: (provided, state) => ({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      ':hover': { backgroundColor: '#F8F8F8' },
      backgroundColor:
        state.isSelected || state.isFocused ? '#F8F8F8' : 'transparent',
      color: state.isSelected ? 'hsl(0, 0%, 30%)' : 'none',
      borderRadius: '6px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      position: 'absolute',
      width: 'fit-content',
      padding: '0 4px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      position: 'absolute',
      right: 0,
      top: 0,
    }),
    placeholder: (provided) => ({ ...provided, color: 'hsl(0, 0%, 70%)' }),
  };
}
