import { addDays } from 'date-fns';
import { defaultProposalStyle } from './const';

export interface Proposal {
  id?: string;
  name?: string;
  currency: string | null;
  publishStatus: PublishStatus;
  acceptanceStatus: AcceptanceStatus;
  vatPercentage: number;
  imageUrl: string | null;
  creationDateTime: number;
  displayDateTime: number | undefined;
  validUntilDateTime: number;
  acceptanceDateTime?: number | null;
  lastViewedDateTime?: number | null;
  publishDateTime?: number | null;
  client: Client | null;
  segments: ProposalSegment[];
  companyInfo: Settings | null;
  visibility: 'public' | 'protected';
  currentPreset: 'default' | 'dark';
  ownerNotifiedDate: number | null;
  customTags: string[];
  style: ProposalStyle;
  totalDiscount: ProposalTotalDiscount;
  saved?: boolean;
  isPDFUpToDate?: boolean;
  lastPDFSaveType?: 'one-page' | 'multi-page' | null;
  responseStatus: 'allowed' | 'disallowed';
  servicePricesVisibility: 'hidden' | 'visible';
  dualPricing?: ProposalDualPricing;
  isInvoice?: boolean;
  paymentMethod?: string | null;
  additionalInfo?: string;
}

export class Proposal {
  constructor(data?: Proposal) {
    this.id = data?.id;
    this.name = data?.name;
    this.publishStatus = data?.publishStatus || 'draft';
    this.acceptanceStatus = data?.acceptanceStatus || 'pending';
    this.currentPreset = data?.currentPreset || 'default';
    this.client = data?.client || null;
    this.companyInfo = data?.companyInfo || null;
    this.vatPercentage = data?.vatPercentage || 0;
    this.currency = data?.currency || null;
    this.imageUrl = data?.imageUrl || null;
    this.creationDateTime = data?.creationDateTime || +new Date();
    this.displayDateTime = data?.displayDateTime || +new Date();
    this.lastViewedDateTime = data?.lastViewedDateTime || null;
    this.validUntilDateTime =
      data?.validUntilDateTime || +new Date(addDays(new Date(), 14));
    this.publishDateTime = data?.publishDateTime || null;
    this.acceptanceDateTime = data?.acceptanceDateTime || null;
    this.segments = data?.segments || [];
    this.visibility = data?.visibility || 'public';
    this.ownerNotifiedDate = data?.ownerNotifiedDate || null;
    this.customTags = data?.customTags || [];
    this.saved = false;
    this.isPDFUpToDate = false;
    this.lastPDFSaveType = data?.lastPDFSaveType || null;
    this.responseStatus = data?.responseStatus || 'allowed';
    this.servicePricesVisibility = data?.servicePricesVisibility || 'visible';

    this.style = {
      color: {
        proposalTitle:
          data?.style?.color?.proposalTitle ||
          defaultProposalStyle?.color?.proposalTitle,
        headings:
          data?.style?.color?.headings || defaultProposalStyle?.color?.headings,
        subheadings:
          data?.style?.color?.subheadings ||
          defaultProposalStyle?.color?.subheadings,
        cardTitle:
          data?.style?.color?.cardTitle ||
          defaultProposalStyle?.color?.cardTitle,
        bodyText:
          data?.style?.color?.bodyText || defaultProposalStyle?.color?.bodyText,
        price: data?.style?.color?.price || defaultProposalStyle?.color?.price,
        subtotalPrice:
          data?.style?.color?.subtotalPrice ||
          defaultProposalStyle?.color?.subtotalPrice,
        cardBackground:
          data?.style?.color?.cardBackground ||
          defaultProposalStyle?.color?.cardBackground,
        background:
          data?.style?.color?.background ||
          defaultProposalStyle?.color?.background,
        borders:
          data?.style?.color?.borders || defaultProposalStyle?.color?.borders,
        accentColor:
          data?.style?.color?.accentColor ||
          defaultProposalStyle?.color?.accentColor,
        buttonSecondary:
          data?.style?.color?.buttonSecondary ||
          defaultProposalStyle?.color?.buttonSecondary,
      },
      typography: {
        heading: {
          fontFamily:
            data?.style?.typography?.heading?.fontFamily ||
            defaultProposalStyle?.typography?.heading?.fontFamily,
          fontWeight:
            data?.style?.typography?.heading?.fontWeight ||
            defaultProposalStyle?.typography?.heading?.fontWeight,
        },
        body: {
          fontFamily:
            data?.style?.typography?.body?.fontFamily ||
            defaultProposalStyle?.typography?.body?.fontFamily,
          fontWeight:
            data?.style?.typography?.body?.fontWeight ||
            defaultProposalStyle?.typography?.body?.fontWeight,
        },
      },
    };

    this.totalDiscount = {
      discount: data?.totalDiscount?.discount || 0,
      discountType: data?.totalDiscount?.discountType || 'absolute',
    };

    this.dualPricing = {
      enabled: data?.dualPricing?.enabled || false,
      dualCurrency: data?.dualPricing?.dualCurrency || null,
      exchangeRate: data?.dualPricing?.exchangeRate || null,
    };

    this.isInvoice = data?.isInvoice || false;
    this.paymentMethod = data?.paymentMethod || null;
    this.additionalInfo = data?.additionalInfo || '';
  }
}
