import { useScrollIntoView } from 'modules/authentication';
import React, { useCallback, useEffect, useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

export const AdditionalInfoInput: React.FC = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<PublishStatusAware<Proposal>>();
  const { handleFocus } = useScrollIntoView();
  const { formatMessage } = useIntl();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref, ...registerProps } = register('additionalInfo');
  const value = useWatch({
    name: 'additionalInfo',
  });

  const handleRef = useCallback(
    (instance: HTMLTextAreaElement | null) => {
      ref(instance);
      textareaRef.current = instance;
    },
    [ref],
  );

  const adjustHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, []);

  useEffect(() => {
    adjustHeight();
  }, [value, adjustHeight]);

  return (
    <FieldWrapper
      name="additionalInfo"
      errors={errors}
      labelId="inputs.additional-info.label"
    >
      <Controller
        name="additionalInfo"
        control={control}
        render={({ field }) => (
          <textarea
            {...field}
            {...registerProps}
            ref={handleRef}
            onFocus={handleFocus}
            className="input input__box input__textarea account-delete-modal__textarea"
            placeholder={formatMessage({
              id: 'inputs.additional-info.placeholder',
            })}
            data-cy="additional-info-input"
            rows={1}
          />
        )}
      />
    </FieldWrapper>
  );
};
