import { DualPrice, Price, SinglePrice, formatPrice } from 'modules/proposals';
import React from 'react';

interface Props {
  currency: string;
  totalAmount: number;
  exchangeRate?: number;
  dualCurrency?: string;
  ISOCountry?: string;
}

export const TotalProposalPrice: React.FC<Props> = ({
  currency,
  totalAmount,
  exchangeRate,
  dualCurrency = 'eur',
  ISOCountry,
}) => (
  <Price
    currency={currency}
    dualCurrency={dualCurrency}
    customExchangeRate={exchangeRate}
    dualPriceElement={
      <DualPrice>
        <p className="t-eta">
          <strong>{formatPrice(totalAmount, currency, ISOCountry)}</strong>
        </p>
        {exchangeRate && (
          <p className="text--xxs__responsive o-60">
            {formatPrice(totalAmount * exchangeRate, dualCurrency, ISOCountry)}
          </p>
        )}
      </DualPrice>
    }
    singlePriceElement={
      <SinglePrice>
        <strong className="t-eta t-ellipsis">
          {formatPrice(totalAmount, currency, ISOCountry)}
        </strong>
      </SinglePrice>
    }
  />
);
