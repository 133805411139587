import {
  formatPercentage,
  generateColor,
  generateTypography,
  useDualPricing,
  useProposalTotalPrices,
} from 'modules/proposals';
import { proposalOwnerCountryAtom } from 'modules/settings';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { useISOCountry } from 'shared/hooks';
import {
  DiscountPublicProposalPrice,
  SubtotalAfterDiscountPublicProposalPrice,
  SubtotalPublicProposalPrice,
  TotalPublicProposalPrice,
  VatAmountPublicProposalPrice,
} from './fragments';

interface Props {
  vatPercentage: number;
  proposal: Proposal;
  segments: ProposalSegment[];
  currency: string;
  discountType: ProposalDiscountType;
  totalDiscountAmount: number;
  style: ProposalStyle;
}

export const PublicProposalPrices: React.FC<Props> = ({
  segments,
  vatPercentage,
  discountType,
  totalDiscountAmount,
  currency,
  proposal,
}) => {
  const { exchangeRate } = useDualPricing(
    currency,
    proposal?.dualPricing?.dualCurrency || 'eur',
    proposal?.dualPricing?.exchangeRate || undefined,
  );

  const {
    subtotalAmount,
    vatAmount,
    totalAmount,
    servicesDiscountAmount,
    subtotalIncludingTotalDiscount,
    subtotalAfterDiscount,
    allDiscountsCombined,
  } = useProposalTotalPrices(
    vatPercentage,
    segments,
    discountType,
    totalDiscountAmount,
  );
  const country = useRecoilValue(proposalOwnerCountryAtom);
  const { ISOCountry } = useISOCountry(country);

  return (
    <div
      className={`wrapper  ${
        proposal?.servicePricesVisibility === 'hidden'
          ? 'wrapper--extra-tight'
          : 'wrapper--tight'
      } proposal__details__wrapper`}
      style={{
        fontFamily: generateTypography('body', proposal).fontFamily,
        fontWeight: generateTypography('body', proposal).fontWeight,
      }}
    >
      <div className="proposal__total">
        <ul
          className="proposal__total__list t-ellipsis"
          style={{
            color: generateColor('price', proposal),
          }}
        >
          <li className="proposal__total__list__item">
            <span className="proposal__total__list__label t-eta">
              <FormattedMessage id="proposals.prices.subtotal" />
            </span>
            <span className="proposal__total__list__value">
              <SubtotalPublicProposalPrice
                currency={currency}
                subtotalAmount={subtotalAmount}
                exchangeRate={exchangeRate}
                dualCurrency={proposal?.dualPricing?.dualCurrency || 'eur'}
                ISOCountry={ISOCountry}
              />
            </span>
          </li>
          {servicesDiscountAmount > 0 && (
            <li className="proposal__total__list__item">
              <span className="proposal__total__list__label t-eta">
                <FormattedMessage id="proposals.prices.service-discount" />
              </span>
              <span className="proposal__total__list__value t-eta">
                <DiscountPublicProposalPrice
                  currency={currency}
                  discountAmount={servicesDiscountAmount}
                  exchangeRate={exchangeRate}
                  dualCurrency={proposal?.dualPricing?.dualCurrency || 'eur'}
                  ISOCountry={ISOCountry}
                />
              </span>
            </li>
          )}
          {totalDiscountAmount > 0 && (
            <>
              <li className="proposal__total__list__item">
                <span className="proposal__total__list__label t-eta">
                  Subtotal discount:{' '}
                </span>
                <span className="proposal__total__list__value t-eta">
                  <SubtotalAfterDiscountPublicProposalPrice
                    currency={currency}
                    subtotalAfterDiscount={subtotalAfterDiscount}
                    discountType={discountType}
                    discountAmount={totalDiscountAmount}
                    exchangeRate={exchangeRate}
                    dualCurrency={proposal?.dualPricing?.dualCurrency || 'eur'}
                    ISOCountry={ISOCountry}
                  />
                </span>
              </li>
              <li className="proposal__total__list__item">
                <span className="proposal__total__list__label t-eta">
                  All discounts combined:
                </span>
                <DiscountPublicProposalPrice
                  currency={currency}
                  discountAmount={allDiscountsCombined}
                  exchangeRate={exchangeRate}
                  dualCurrency={proposal?.dualPricing?.dualCurrency || 'eur'}
                  ISOCountry={ISOCountry}
                />
              </li>
            </>
          )}
          <li
            className="proposal__total__list__item proposal__total__list__item--total t-ellipsis g-16"
            style={{
              borderTop: `1px dashed ${generateColor('borders', proposal)}`,
            }}
          >
            <span className="proposal__total__list__label t-eta">
              Subtotal (incl. discounts):
            </span>
            <SubtotalPublicProposalPrice
              currency={currency}
              subtotalAmount={subtotalIncludingTotalDiscount}
              exchangeRate={exchangeRate}
              dualCurrency={proposal?.dualPricing?.dualCurrency || 'eur'}
              ISOCountry={ISOCountry}
            />
          </li>
          <li className="proposal__total__list__item">
            <span className="proposal__total__list__label t-eta">
              <FormattedMessage id="proposals.prices.vat" />
            </span>
            <span className="proposal__total__list__value t-eta">
              {formatPercentage(vatPercentage / 100, ISOCountry)}
            </span>
          </li>
          <li className="proposal__total__list__item">
            <span className="proposal__total__list__label t-eta">
              <FormattedMessage id="proposals.prices.vat-amount" />
            </span>
            <span className="proposal__total__list__value">
              <VatAmountPublicProposalPrice
                currency={currency}
                vatAmount={vatAmount}
                exchangeRate={exchangeRate}
                dualCurrency={proposal?.dualPricing?.dualCurrency || 'eur'}
                ISOCountry={ISOCountry}
              />
            </span>
          </li>
          <li
            className="proposal__total__list__item proposal__total__list__item--total t-ellipsis g-16"
            style={{
              borderTop: `1px dashed ${generateColor('borders', proposal)}`,
              color: generateColor('subtotalPrice', proposal),
            }}
          >
            <span className="proposal__total__list__label t-eta t-bold">
              <FormattedMessage id="proposals.prices.total" />
            </span>
            <span className="proposal__total__list__value t-epsilon">
              <TotalPublicProposalPrice
                currency={currency}
                totalAmount={totalAmount}
                exchangeRate={exchangeRate}
                dualCurrency={proposal?.dualPricing?.dualCurrency || 'eur'}
                ISOCountry={ISOCountry}
              />
            </span>
          </li>
          {proposal?.additionalInfo && (
            <p className="f text--xxs__responsive proposal__additional_info">
              {proposal?.additionalInfo}
            </p>
          )}
          {/* {exchangeRate && (
            <li className="proposal__total__list__item">
              <span className="proposal__total__list__label t-small t-text-3">
                <FormattedMessage id="proposals.prices.conversion-rate" />
              </span>
              <span className="proposal__total__list__value t-small t-text-3">
                <FormattedMessage
                  id={`proposals.prices.${currency}-conversion`}
                  values={{
                    value: exchangeRate,
                    dualCurrency: 'USD',
                  }}
                />
              </span>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};
