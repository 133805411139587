import { useMemo } from 'react';
import { CURRENCIES_WITH_DUAL_PRICING } from '../const';

export function useDualPricing(
  currency: string | null,
  dualCurrency = 'eur',
  customRate?: number,
) {
  const fixedCurrency = useMemo(
    () =>
      CURRENCIES_WITH_DUAL_PRICING.find((item) => item.currency === currency),
    [currency],
  );

  const exchangeRate = useMemo(() => {
    if (fixedCurrency?.fixedExchangeRate) {
      return fixedCurrency?.fixedExchangeRate;
    }

    return customRate;
  }, [fixedCurrency, customRate]);

  return {
    exchangeRate,
    dualCurrency,
  };
}
