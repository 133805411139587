import { PageProps } from 'gatsby';
import { DashboardLayout } from 'modules/dashboard';
import React from 'react';
import { useIntl } from 'react-intl';
import { PageLoading, SEO } from 'shared/components';
import { useProposalsLoadingSelector } from '../state';
import { InvoicesSection } from './InvoicesSection';

export const Invoices: React.FC<PageProps> = ({ location }) => {
  const { formatMessage } = useIntl();
  const loading = useProposalsLoadingSelector();

  if (loading) return <PageLoading isLoading={loading} />;

  return (
    <>
      <SEO
        title={formatMessage({ id: 'invoices.seo.title' })}
        description={formatMessage({ id: 'invoices.seo.description' })}
        href={location.href}
      />
      <DashboardLayout>
        <InvoicesSection />
      </DashboardLayout>
    </>
  );
};
