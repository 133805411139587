export enum AppRoute {
  Dashboard = '/dashboard',
  Proposals = '/proposals/:filter',
  ProposalsOverview = '/proposals/overview',
  ProposalsDraft = '/proposals/draft',
  ProposalsPublished = 'proposals/published',
  ProposalsArchived = 'proposals/archived',
  ProposalsTemplate = 'proposals/template',
  ProposalInvoices = 'invoices',
  ProposalsStatistics = 'proposals/statistics',
  ProposalEditor = '/proposal/:id',
  Company = '/settings/company-info',
  ProposalSettings = '/settings/proposal',
  Address = '/settings/address',
  Tax = '/settings/tax',
  Subscription = '/subscription',
  SubscriptionSettings = '/settings/subscription',
  Account = '/settings/account',
  Clients = '/clients',
  Units = '/units',
  Services = '/services',
}
