import { DualPrice, Price, SinglePrice, formatPrice } from 'modules/proposals';
import React from 'react';

interface Props {
  currency: string;
  vatAmount: number;
  exchangeRate?: number;
  dualCurrency?: string;
  ISOCountry?: string;
}

export const VatAmountPublicProposalPrice: React.FC<Props> = ({
  currency,
  vatAmount,
  exchangeRate,
  dualCurrency = 'eur',
  ISOCountry,
}) => (
  <Price
    currency={currency}
    dualCurrency={dualCurrency}
    customExchangeRate={exchangeRate}
    dualPriceElement={
      <DualPrice className="t-eta">
        <p className="t-eta">{formatPrice(vatAmount, currency, ISOCountry)}</p>
        {exchangeRate && (
          <p className="o-60 text--xxs__responsive">
            {formatPrice(vatAmount * exchangeRate, dualCurrency, ISOCountry)}
          </p>
        )}
      </DualPrice>
    }
    singlePriceElement={
      <SinglePrice className="t-eta">
        <span>{formatPrice(vatAmount, currency, ISOCountry)}</span>
      </SinglePrice>
    }
  />
);
