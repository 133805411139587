import { DualPrice, Price, SinglePrice, formatPrice } from 'modules/proposals';
import React from 'react';

interface Props {
  currency: string;
  discountAmount: number;
  discountType: ProposalDiscountType;
  subtotalAfterDiscount: number;
  exchangeRate?: number;
  dualCurrency?: string;
  ISOCountry?: string;
}

export const TotalDiscountPrice: React.FC<Props> = ({
  currency,
  discountAmount,
  discountType,
  subtotalAfterDiscount,
  exchangeRate,
  dualCurrency = 'eur',
  ISOCountry,
}) => {
  if (discountType === 'absolute') {
    return (
      <Price
        currency={currency}
        dualCurrency={dualCurrency}
        customExchangeRate={exchangeRate}
        dualPriceElement={
          <DualPrice>
            <p className="t-eta">
              {formatPrice(-discountAmount || -0, currency, ISOCountry)}
            </p>
            {exchangeRate && (
              <p className="o-60 text--xxs__responsive">
                {formatPrice(
                  -discountAmount * exchangeRate || 0,
                  dualCurrency,
                  ISOCountry,
                )}
              </p>
            )}
          </DualPrice>
        }
        singlePriceElement={
          <SinglePrice className="t-eta">
            {formatPrice(-discountAmount || -0, currency, ISOCountry)}
          </SinglePrice>
        }
      />
    );
  }

  return (
    <Price
      currency={currency}
      dualCurrency={dualCurrency}
      customExchangeRate={exchangeRate}
      dualPriceElement={
        <DualPrice>
          <span>-{discountAmount}%</span>
          <p className="t-eta">
            {formatPrice(
              (-subtotalAfterDiscount * discountAmount) / 100,
              currency,
              ISOCountry,
            )}
          </p>
          {exchangeRate && (
            <p className="o-60 text--xxs__responsive">
              {formatPrice(
                ((-subtotalAfterDiscount * discountAmount) / 100) *
                  exchangeRate,
                dualCurrency,
                ISOCountry,
              )}
            </p>
          )}
        </DualPrice>
      }
      singlePriceElement={
        <SinglePrice className="t-eta">
          <div className="f f--col f--align-end">
            <span>-{discountAmount}%</span>
            <span>
              {formatPrice(
                (-subtotalAfterDiscount * discountAmount) / 100,
                currency,
                ISOCountry,
              )}
            </span>
          </div>
        </SinglePrice>
      }
    />
  );
};
