import { Clients } from 'modules/clients';
import {
  Invoices,
  ProposalBuilder,
  Proposals,
  ProposalsOverview,
  ProposalsStatistics,
} from 'modules/proposals';
import { Services } from 'modules/services';
import {
  AccountSettings,
  AddressSettings,
  CompanySettings,
  ProposalSettings,
  TaxSettings,
} from 'modules/settings';
import { UnitsPage } from 'modules/units';
import Error404Page from 'src/pages/404';
import { AppRoute } from '../const';
import React from 'react';

type Props = PageProps & { proposalsLoading: boolean };

export const ApplicationRoutes = ({ proposalsLoading, ...props }: Props) => [
  <ProposalsOverview
    key={props.path}
    {...props}
    path={AppRoute.ProposalsOverview}
  />,
  <Proposals
    key={props.path}
    {...props}
    path={AppRoute.ProposalsDraft}
    publishStatus="draft"
  />,
  <Proposals
    key={props.path}
    {...props}
    path={AppRoute.ProposalsPublished}
    publishStatus="published"
  />,
  <Proposals
    key={props.path}
    {...props}
    path={AppRoute.ProposalsArchived}
    publishStatus="archived"
  />,
  <Proposals
    key={props.path}
    {...props}
    path={AppRoute.ProposalsTemplate}
    publishStatus="template"
  />,
  <Invoices key={props.path} {...props} path={AppRoute.ProposalInvoices} />,
  <ProposalsStatistics
    key={props.path}
    {...props}
    path={AppRoute.ProposalsStatistics}
  />,
  <ProposalBuilder
    key={props.path}
    {...props}
    path={AppRoute.ProposalEditor}
  />,
  <Clients
    key={props.path}
    {...props}
    proposalsLoading={proposalsLoading}
    path={AppRoute.Clients}
  />,
  <UnitsPage
    key={props.path}
    {...props}
    proposalsLoading={proposalsLoading}
    path={AppRoute.Units}
  />,
  <Services
    key={props.path}
    {...props}
    proposalsLoading={proposalsLoading}
    path={AppRoute.Services}
  />,
  <CompanySettings key={props.path} {...props} path={AppRoute.Company} />,
  <ProposalSettings
    key={props.path}
    {...props}
    path={AppRoute.ProposalSettings}
  />,
  <AddressSettings key={props.path} {...props} path={AppRoute.Address} />,
  <TaxSettings key={props.path} {...props} path={AppRoute.Tax} />,
  <AccountSettings key={props.path} {...props} path={AppRoute.Account} />,
  <Error404Page default key={props.path} {...props} />,
];
