import { DualPrice, Price, SinglePrice, formatPrice } from 'modules/proposals';
import React from 'react';

interface Props {
  currency: string;
  subtotalAmount: number;
  exchangeRate?: number;
  dualCurrency?: string;
  ISOCountry?: string;
}

export const SubtotalPublicProposalPrice: React.FC<Props> = ({
  currency,
  subtotalAmount,
  exchangeRate,
  dualCurrency = 'eur',
  ISOCountry,
}) => (
  <Price
    currency={currency}
    dualCurrency={dualCurrency}
    customExchangeRate={exchangeRate}
    dualPriceElement={
      <DualPrice>
        <p className="t-eta">
          {formatPrice(subtotalAmount, currency, ISOCountry)}
        </p>
        {exchangeRate && (
          <p className="o-60 text--xxs__responsive">
            {formatPrice(
              subtotalAmount * exchangeRate,
              dualCurrency,
              ISOCountry,
            )}
          </p>
        )}
      </DualPrice>
    }
    singlePriceElement={
      <SinglePrice className="t-eta">
        {formatPrice(subtotalAmount, currency, ISOCountry)}
      </SinglePrice>
    }
  />
);
