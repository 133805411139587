import { DualPrice, Price, SinglePrice, formatPrice } from 'modules/proposals';
import React from 'react';

interface Props {
  currency: string;
  totalSubtotal: number;
  exchangeRate?: number;
  dualCurrency?: string;
  ISOCountry?: string;
}

export const SubtotalPublicServiceSegmentCardPrice: React.FC<Props> = ({
  currency,
  totalSubtotal,
  exchangeRate,
  dualCurrency = 'eur',
  ISOCountry,
}) => (
  <Price
    currency={currency}
    dualCurrency={dualCurrency}
    customExchangeRate={exchangeRate}
    dualPriceElement={
      <DualPrice>
        <p className="t-eta">
          <strong>{formatPrice(totalSubtotal, currency, ISOCountry)}</strong>
        </p>
        {exchangeRate && (
          <p className="o-60 text--xxs__responsive">
            {formatPrice(
              totalSubtotal * exchangeRate,
              dualCurrency,
              ISOCountry,
            )}
          </p>
        )}
      </DualPrice>
    }
    singlePriceElement={
      <SinglePrice className="t-eta">
        <strong>{formatPrice(totalSubtotal, currency, ISOCountry)}</strong>
      </SinglePrice>
    }
  />
);
