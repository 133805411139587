import { useOrganizationIdSelector } from 'modules/authorization';
import { useFormGuard, useScrollToError } from 'modules/form';
import {
  EditorActions,
  EditorHeader,
  Proposal,
  ProposalEditorLayout,
  ProposalPrices,
  PublicProposalInfo,
  SegmentList,
  generateColor,
  useProposalActions,
  useProposalSummary,
} from 'modules/proposals';
import {
  EditorBanner,
  PropozePlusUpgradeModal,
  SubscriptionTierWrapper,
} from 'modules/subscription';
import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ConfirmationModal, DashboardFooter, SEO } from 'shared/components';
import { useMobile } from 'shared/hooks';

interface Props {
  proposal: PublishStatusAware<Proposal>;
}

export const ProposalEditor: React.FC<Props> = ({ proposal }) => {
  const isMobile = useMobile();
  const { formatMessage } = useIntl();
  const { view, toggleView } = useProposalSummary();

  const organizationId = useOrganizationIdSelector();

  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const editorTitle = proposal?.name
    ? `${proposal?.name} | Propoze`
    : formatMessage({ id: 'proposals.seo.builderTitle' });

  const {
    submit,
    onCancel,
    onUpdate,
    getPublishStatusModalProps,
    form,
    publishStatusModal,
    acceptanceStatusModal,
  } = useProposalActions(proposal);
  useFormGuard(form);
  const elementWithError = useScrollToError(form);
  const modalProps = getPublishStatusModalProps();

  function handleCloseUpgradeModal() {
    setIsModalVisible(false);
  }

  // Use formState.submitCount for dependency because
  // it's the only parameter that's changing accordingly
  // with pressing the buttons that submit the form.
  useEffect(() => {
    if (!elementWithError) return;
    elementWithError.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [form.formState.submitCount]);

  return (
    <>
      <SEO
        title={editorTitle}
        description={formatMessage({ id: 'proposals.seo.builderDescription' })}
      />
      <ProposalEditorLayout
        footerClassName="d--desktop--only u-bg--4"
        contentClassName="proposal-create__container"
        contentId="proposal-create__container"
        footerComponent={DashboardFooter}
        isMobile={isMobile}
        isSummaryOpen={isSummaryOpen}
      >
        <SubscriptionTierWrapper tier="free" exact>
          <EditorBanner onOpenPlusModal={setIsModalVisible} />
        </SubscriptionTierWrapper>
        <section>
          <FormProvider {...form}>
            <form
              style={
                view === 'brand-customization'
                  ? {
                      backgroundColor: generateColor(
                        'background',
                        form.getValues(),
                      ),
                    }
                  : {}
              }
              onSubmit={form.handleSubmit(submit)}
            >
              <EditorActions
                view={view}
                isSummaryOpen={isSummaryOpen}
                proposal={proposal}
                toggleView={toggleView}
                onSetSummaryOpen={setIsSummaryOpen}
              />

              {view === 'table-of-contents' ? (
                <>
                  <EditorHeader />
                  <div className="wrapper--editor">
                    {/* <ProposalDualPricingSettings /> */}
                    <SegmentList formState={form.formState} />
                    <ProposalPrices proposal={proposal} />
                  </div>
                </>
              ) : (
                <PublicProposalInfo
                  proposal={form.watch()}
                  organizationId={organizationId}
                  logoUrl={form.getValues('companyInfo.companyImage')}
                  hasPermissions={true}
                  authorized={true}
                  toggleView={toggleView}
                  isInBuilder
                />
              )}
            </form>
            {publishStatusModal.isModalOpen && modalProps && (
              <ConfirmationModal
                onClose={onCancel}
                onSubmit={onUpdate}
                title={modalProps['title']}
                subtitle={modalProps['subtitle']}
                confirmationButtonLabel={modalProps['confirmationButtonLabel']}
                declineButtonLabel={modalProps['declineButtonLabel']}
                type={modalProps['type']}
              />
            )}
            <SubscriptionTierWrapper tier="plus">
              {acceptanceStatusModal.isModalOpen && (
                <ConfirmationModal
                  onClose={acceptanceStatusModal.closeModal}
                  onSubmit={onUpdate}
                  title={formatMessage({
                    id: 'proposal-editor.acceptance-status-modal.title',
                  })}
                  subtitle={formatMessage({
                    id: 'proposal-editor.acceptance-status-modal.description',
                  })}
                  confirmationButtonLabel={formatMessage({
                    id: 'proposal-editor.acceptance-status-modal.confirm',
                  })}
                  declineButtonLabel={formatMessage({
                    id: 'proposal-editor.acceptance-status-modal.cancel',
                  })}
                />
              )}
            </SubscriptionTierWrapper>
          </FormProvider>
        </section>
        {isModalVisible && (
          <PropozePlusUpgradeModal onClose={handleCloseUpgradeModal} />
        )}
      </ProposalEditorLayout>
    </>
  );
};
