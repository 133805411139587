import { authorizationSelector } from 'modules/authorization';
import { clientSelector } from 'modules/clients';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFuseObjectSearch, useLocalStorage } from 'shared/hooks';
import { sortDescendingFromNewest } from 'shared/utils';
import { useProposalsSelector } from '../state';
import { INVOICES_SECTION_PASSWORD } from '../views/InvoicesSection';

export function useProposalGlobalSearch(query: string) {
  const proposals = useProposalsSelector();
  const { documents } = useSelector(clientSelector.getState);
  const { adminRole } = useSelector(authorizationSelector.getState);
  const [invoicesPassword] = useLocalStorage<string>('invoicesPagePassword');

  const filteredClients = useMemo(
    () =>
      [...useFuseObjectSearch(['name'], query, documents)].sort(
        sortDescendingFromNewest,
      ),
    [query, documents],
  );

  const filteredProposals = useMemo(
    () =>
      [
        ...useFuseObjectSearch(
          ['name', 'client.name'],
          query,
          adminRole === 'superAdmin' &&
            invoicesPassword === INVOICES_SECTION_PASSWORD
            ? proposals
            : proposals.filter((proposal) => !proposal.isInvoice),
        ),
      ].sort(sortDescendingFromNewest),
    [query, proposals, invoicesPassword, adminRole],
  );

  return {
    proposals: filteredProposals,
    clients: filteredClients,
  };
}
