import { formatPercentage, useDualPricing } from 'modules/proposals';
import { proposalOwnerCountryAtom } from 'modules/settings';
import React, { useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { RichTextEditor } from 'shared/components';
import { useISOCountry } from 'shared/hooks';
import {
  DiscountPublicServiceSegmentCardPrice,
  SubtotalPublicServiceSegmentCardPrice,
  TotalPublicServiceSegmentCardPrice,
  UnitPublicServiceSegmentCardPrice,
} from './fragments';

interface Props {
  item: ServiceSegment;
  index: number;
  currency: Proposal['currency'];
  style: Proposal['style'];
  servicePricesVisibility?: Proposal['servicePricesVisibility'];
  dualPricing?: Proposal['dualPricing'];
}

export const PublicServiceSegmentCard: React.FC<Props> = ({
  item,
  index,
  currency,
  style: { color, typography },
  servicePricesVisibility,
  dualPricing,
}) => {
  const { pricePerUnit, quantity, discount } = item;
  const { exchangeRate } = useDualPricing(
    currency,
    dualPricing?.dualCurrency || 'eur',
    dualPricing?.exchangeRate || undefined,
  );
  const country = useRecoilValue(proposalOwnerCountryAtom);
  const { ISOCountry } = useISOCountry(country);

  const totalPrice = useMemo(
    () => pricePerUnit * quantity,
    [pricePerUnit, quantity],
  );
  const totalDiscount = useMemo(
    () => totalPrice * (discount / 100),
    [totalPrice, discount],
  );
  const totalSubtotal = useMemo(
    () => totalPrice - totalDiscount,
    [totalPrice, totalDiscount],
  );

  if ((!item.service && !item.unit) || !currency) return null;

  return (
    <div
      style={{
        backgroundColor: color.cardBackground,
        fontFamily: typography.body.fontFamily,
        fontWeight: typography.body.fontWeight,
      }}
      className="proposal-block-service"
      id={`service-segment-${index}`}
    >
      <div
        className="proposal-block-service__info"
        style={{
          ...(servicePricesVisibility === 'hidden' && {
            width: '100%',
            maxWidth: '100%',
            flexBasis: '100%',
          }),
        }}
      >
        <h3
          style={{
            color: color.cardTitle,
            fontFamily: typography.heading.fontFamily,
            fontWeight: typography.heading.fontWeight,
          }}
          className="proposal-block-service__name t-zeta"
        >
          {item.service?.name}
        </h3>
        <RichTextEditor
          value={item.content}
          bodyText={color.bodyText}
          index={index}
        />
      </div>
      {servicePricesVisibility !== 'hidden' && (
        <div className="proposal-block-service__pricing">
          <div
            className="proposal-block-service__pricing__top"
            style={{
              color: color.price,
              borderBottom: `1px solid ${color.borders}`,
            }}
          >
            <div className="proposal-block-service__pricing__top__quantity proposal-block-service__pricing__column">
              <p className="t-eta s-right--xlrg">
                <FormattedMessage id="proposals.quantity" />
                <span className="t-lower"> ({item.unit?.name}): </span>
              </p>
              <span className="t-eta">
                <FormattedNumber value={quantity} />
              </span>
            </div>
            <div className="proposal-block-service__pricing__top__price proposal-block-service__pricing__column">
              <p className="t-eta">
                <FormattedMessage id="proposals.unit-price" />
              </p>
              <UnitPublicServiceSegmentCardPrice
                currency={currency}
                pricePerUnit={pricePerUnit}
                exchangeRate={exchangeRate}
                dualCurrency={dualPricing?.dualCurrency || 'eur'}
                ISOCountry={ISOCountry}
              />
            </div>
            <div className="proposal-block-service__pricing__top__totalvalue proposal-block-service__pricing__column">
              <p className="t-eta">
                <FormattedMessage id="proposals.price" />
              </p>
              <TotalPublicServiceSegmentCardPrice
                currency={currency}
                totalPrice={totalPrice}
                exchangeRate={exchangeRate}
                dualCurrency={dualPricing?.dualCurrency || 'eur'}
                ISOCountry={ISOCountry}
              />
            </div>
          </div>
          {Number(item.discount) !== 0 && (
            <div
              className="proposal-block-service__pricing__discount"
              style={{
                color: color.price,
                borderBottom: `1px solid ${color.borders}`,
              }}
            >
              <div>
                <p className="t-eta">
                  <FormattedMessage id="proposals.discount" /> (
                  {formatPercentage(discount / 100, ISOCountry)}):
                </p>
              </div>
              <div /> {/* This extra DIV is for flexbox layouting purposes */}
              <div className="t-right t-ellipsis">
                <DiscountPublicServiceSegmentCardPrice
                  currency={currency}
                  totalDiscount={totalDiscount}
                  exchangeRate={exchangeRate}
                  dualCurrency={dualPricing?.dualCurrency || 'eur'}
                  ISOCountry={ISOCountry}
                />
              </div>
            </div>
          )}
          <div
            style={{ color: color.subtotalPrice }}
            className="proposal-block-service__pricing__subtotal"
          >
            <strong className="t-eta">
              <FormattedMessage id="proposals.prices.subtotal" />
            </strong>
            <div />
            <div className="t-right t-ellipsis">
              <SubtotalPublicServiceSegmentCardPrice
                currency={currency}
                totalSubtotal={totalSubtotal}
                exchangeRate={exchangeRate}
                dualCurrency={dualPricing?.dualCurrency || 'eur'}
                ISOCountry={ISOCountry}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
