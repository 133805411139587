import React, { useCallback } from 'react';
import Flatpickr from 'react-flatpickr';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

export const DisplayDateTimePicker: React.FC = () => {
  const { formatMessage } = useIntl();

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<PublishStatusAware<Proposal>>();

  const handleDateChange = useCallback(
    (
      field: ControllerRenderProps<
        PublishStatusAware<Proposal>,
        'displayDateTime'
      >,
    ) =>
      ([selectedDate]: Date[]) => {
        field.onChange(+selectedDate);
      },
    [],
  );

  return (
    <FieldWrapper
      name="displayDateTime"
      labelId={
        getValues('isInvoice')
          ? 'datepickers.invoice-date.label'
          : 'datepickers.display-date.label'
      }
      isRequired
      errors={errors}
    >
      <Controller
        name="displayDateTime"
        control={control}
        rules={{
          required: formatMessage({
            id: 'datepickers.display-date.required',
          }),
        }}
        defaultValue={getValues('displayDateTime')}
        render={({ field }) => (
          <Flatpickr
            {...field}
            data-enable-time
            options={{
              disableMobile: true,
              dateFormat: 'Y-m-d H:i',
            }}
            className={`input input__med input__box ${
              errors.displayDateTime ? 'input__box__error' : ''
            }`}
            placeholder={formatMessage({
              id: 'datepickers.display-date.placeholder',
            })}
            onChange={handleDateChange(field)}
            data-cy="builder-display-date-picker"
          />
        )}
      />
    </FieldWrapper>
  );
};
