export const confirmationModalOptions: Record<
  PublishStatus,
  Partial<
    Record<
      PublishStatus | 'deleted',
      Omit<ConfirmationModalProps, 'onChange' | 'onSubmit' | 'onClose'>
    >
  >
> = {
  draft: {
    published: {
      title: 'Publish this proposal?',
      subtitle:
        'This will make your proposal available to anyone with the link. You can always unpublish your proposal.',
      confirmationButtonLabel: 'Confirm',
      declineButtonLabel: 'Cancel',
    },
    archived: {
      title: 'Archive this proposal?',
      subtitle: 'You can restore archived proposals anytime.',
      confirmationButtonLabel: 'Confirm',
      declineButtonLabel: 'Cancel',
    },
  },
  published: {
    draft: {
      title: 'Unpublish this proposal?',
      subtitle: 'This returns the proposal to draft state.',
      confirmationButtonLabel: 'Confirm',
      declineButtonLabel: 'Cancel',
    },
    deleted: {
      title: 'Delete this invoice',
      subtitle:
        'Are you sure you want to delete this invoice? Deleted invoices cannot be restored.',
      confirmationButtonLabel: 'Delete',
      declineButtonLabel: 'Cancel',
      type: 'delete',
    },
  },
  template: {
    archived: {
      title: 'Archive this template?',
      subtitle: 'You can restore archived templates anytime.',
      confirmationButtonLabel: 'Confirm',
      declineButtonLabel: 'Cancel',
    },
    deleted: {
      title: 'Delete this template',
      subtitle:
        'Are you sure you want to delete this template? Deleted templates cannot be restored.',
      confirmationButtonLabel: 'Delete',
      declineButtonLabel: 'Cancel',
      type: 'delete',
    },
  },
  archived: {
    draft: {
      title: 'Return to draft?',
      subtitle:
        'Choosing this option restores the archived proposal to its draft state.',
      confirmationButtonLabel: 'Confirm',
      declineButtonLabel: 'Cancel',
    },
    deleted: {
      title: 'Delete this proposal',
      subtitle:
        'Are you sure you want to delete this proposal? Deleted proposals cannot be restored.',
      confirmationButtonLabel: 'Delete',
      declineButtonLabel: 'Cancel',
      type: 'delete',
    },
  },
};
