import eyeIconUnhidden from 'assets/icons/icon-eye-12-crossed.svg';
import eyeIconHidden from 'assets/icons/icon-eye-12.svg';
import { authenticationSelector } from 'modules/authentication';
import { authorizationSelector } from 'modules/authorization';
import { currencyOptions } from 'modules/proposals';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select, { SingleValue } from 'react-select';
import { Button, FieldWrapper, getSelectStyles } from 'shared/components';
import { useLocalStorage, useMobile } from 'shared/hooks';

const predefinedPassword = 'sigurno_je_sigurno316';
const ALLOWED_EMAILS = [
  'marko.boras@prototyp.digital',
  'borasmarko07@gmail.com',
  'boris+0000@prototyp.digital',
  'marko.boras+legacy52@prototyp.digital',
  'owner@prototyp.digital',
];

export const ProposalDualPricingSettings: React.FC = () => {
  const { user } = useSelector(authenticationSelector.getState);
  const { adminRole } = useSelector(authorizationSelector.getState);
  const isMobile = useMobile();
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<PublishStatusAware<Proposal>>();

  const [dualPricingPassword, setDualPricingPassword] = useLocalStorage<string>(
    'dualPricingPassword',
  );
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const enableDualPricing = useWatch({ name: 'dualPricing.enabled' });
  const options = useMemo(
    () => currencyOptions.map((item) => ({ value: item, label: item })),
    [],
  );

  function handleAuthenticate() {
    if (password === predefinedPassword) {
      setDualPricingPassword(password);
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password.');
    }
  }

  function getValue(data: string | null) {
    if (!data) return null;

    return {
      value: data,
      label: data,
    };
  }

  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  const handleCurrencyChange = useCallback(
    (
      field: ControllerRenderProps<
        PublishStatusAware<Proposal>,
        'dualPricing.dualCurrency'
      >,
    ) =>
      (
        selectedOption: SingleValue<{
          value: string;
          label: string;
        }>,
      ) => {
        field.onChange(selectedOption?.value);
      },
    [],
  );

  if (
    adminRole !== 'superAdmin' ||
    !ALLOWED_EMAILS.includes(user?.email || '')
  ) {
    return null;
  }

  if (!isAuthenticated && dualPricingPassword !== predefinedPassword) {
    return (
      <div className="f f--col g-16 s-top--med s-bottom--xlrg">
        <h3>Enter Password to Access Dual Pricing Settings</h3>
        <div className="auth__eye__container">
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            data-cy="form-password-input"
            autoComplete="password"
            className="input input__box input__med"
          />
          <div onClick={togglePasswordVisibility} className="auth__eye">
            {showPassword ? (
              <img src={eyeIconUnhidden} alt="eyeIconUnhidden" />
            ) : (
              <img src={eyeIconHidden} alt="eyeIconHidden" />
            )}
          </div>
        </div>
        <Button
          type="button"
          style="outline"
          size="med"
          onClick={handleAuthenticate}
          className="s-top--sml u-fit--content"
        >
          Unlock
        </Button>
      </div>
    );
  }

  return (
    <div className="f f--col g-16 s-top--med s-bottom--xlrg">
      <h3>Dual Pricing Settings</h3>
      <div className="f f--align-center g-16">
        <label className="toggle__label">
          <Controller
            name="dualPricing.enabled"
            control={control}
            render={({ field }) => (
              <input
                type="checkbox"
                checked={field.value || false}
                onChange={(e) => {
                  if (e.target.checked === false) {
                    setValue('dualPricing', undefined);
                  }
                  field.onChange(e.target.checked);
                }}
                className="toggle__input"
                ref={field.ref}
              />
            )}
          />
          <span className="toggle__span" />
        </label>
        <p> Enable Dual Pricing</p>
      </div>

      {enableDualPricing && (
        <>
          <div>
            <label>Select Dual Currency</label>
            <FieldWrapper name="dualPricing.dualCurrency" errors={errors}>
              <Controller
                name="dualPricing.dualCurrency"
                control={control}
                defaultValue={getValues('dualPricing.dualCurrency') || null}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={options}
                    value={getValue(field.value)}
                    onChange={handleCurrencyChange(field)}
                    styles={getSelectStyles<string>(
                      Boolean(errors.dualPricing?.dualCurrency),
                      124,
                    )}
                    placeholder="Choose a currency"
                    id="currency__styled__select"
                    className="select__currency--input"
                    isSearchable={!isMobile}
                    isClearable
                  />
                )}
              />
            </FieldWrapper>
          </div>
          <div>
            <label>Exchange Rate</label>
            <FieldWrapper name="dualPricing.exchangeRate" errors={errors}>
              <Controller
                name="dualPricing.exchangeRate"
                control={control}
                defaultValue={getValues('dualPricing.exchangeRate') || 0}
                render={({ field, fieldState }) => (
                  <input
                    {...field}
                    inputMode="decimal"
                    type="number"
                    min={0}
                    step={0.0001}
                    className={`input input__med input__box ${
                      fieldState.error ? 'input__box__error' : ''
                    }`}
                    value={field.value ?? ''}
                    onChange={(e) => {
                      const parsedValue =
                        e.target.value === ''
                          ? null
                          : parseFloat(e.target.value);
                      if (parsedValue && parsedValue < 0) return;
                      field.onChange(parsedValue);
                    }}
                    placeholder="Enter exchange rate"
                  />
                )}
              />
            </FieldWrapper>
          </div>
        </>
      )}
    </div>
  );
};
