import { PropsWithChildren } from 'react';

interface Props {
  currency: string;
  dualPriceElement: JSX.Element;
  singlePriceElement: JSX.Element;
  dualCurrency?: string;
  customExchangeRate?: number;
}

export function Price({
  // currency,
  // dualPriceElement,
  singlePriceElement,
  // dualCurrency,
  // customExchangeRate,
}: PropsWithChildren<Props>) {
  // const { exchangeRate } = useDualPricing(
  //   currency,
  //   dualCurrency,
  //   customExchangeRate,
  // );

  // if (exchangeRate) return dualPriceElement;

  return singlePriceElement;
}
