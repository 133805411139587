import { useScrollIntoView } from 'modules/authentication';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

export const InvoicePaymentMethod: React.FC = () => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const {
    formState: { errors },
    register,
  } = useFormContext<PublishStatusAware<Proposal>>();

  return (
    <FieldWrapper
      name="paymentMethod"
      errors={errors}
      labelId="inputs.payment-method.label"
    >
      <input
        type="text"
        autoComplete="off"
        className={`input input__med input__box ${
          errors.name ? 'input__box__error' : ''
        }`}
        placeholder={formatMessage({ id: 'inputs.payment-method.placeholder' })}
        data-cy="builder-payment-method-input"
        onFocus={handleFocus}
        {...register('paymentMethod')}
      />
    </FieldWrapper>
  );
};
