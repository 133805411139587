import eyeIconUnhidden from 'assets/icons/icon-eye-12-crossed.svg';
import eyeIconHidden from 'assets/icons/icon-eye-12.svg';
import { INVOICES_SECTION_PASSWORD } from 'modules/proposals/views/InvoicesSection';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import { useSubmitOnEnter } from 'shared/hooks';

interface Props {
  onSetInvoicesPassword: (password: string) => void;
  onSetIsAuthenticated: (isAuthenticated: boolean) => void;
}

export const ProposalInvoicesLock: React.FC<Props> = ({
  onSetInvoicesPassword,
  onSetIsAuthenticated,
}) => {
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  function handleAuthenticate() {
    if (password === INVOICES_SECTION_PASSWORD) {
      onSetInvoicesPassword(password);
      onSetIsAuthenticated(true);
    } else {
      alert('Incorrect password.');
    }
  }

  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  return (
    <div className="dashboard__section__actions">
      <div className="f f--col">
        <h4>
          <FormattedMessage id="invoices.unlock" />
        </h4>
        <div className="auth__eye__container">
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            name="invoices-password-input"
            data-cy="invoices-password-input"
            autoComplete="new-password"
            className="input input__box input__med"
          />
          <div onClick={togglePasswordVisibility} className="auth__eye">
            {showPassword ? (
              <img src={eyeIconUnhidden} alt="eyeIconUnhidden" />
            ) : (
              <img src={eyeIconHidden} alt="eyeIconHidden" />
            )}
          </div>
        </div>
        <Button
          type="button"
          style="outline"
          onClick={handleAuthenticate}
          size="med"
          ref={submitButtonRef}
          className="s-top--med u-fit--content"
        >
          <FormattedMessage id="buttons.unlock" />
        </Button>
      </div>
    </div>
  );
};
