import failedIcon from 'assets/icons/icon-checkmark-fail-12.svg';
import successIcon from 'assets/icons/icon-checkmark-success-18.svg';
import logoImage from 'assets/images/logo.svg';
import logoImageSmall from 'assets/images/new-logo-small.svg';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useOrganizationIdSelector } from 'modules/authorization';
import { FeatureFlagWrapper } from 'modules/feature-flags';
import {
  ProposalSummaryDrawer,
  useProposalPermissions,
  useProposalSummary,
} from 'modules/proposals';
import { subscriptionSelectors } from 'modules/subscription';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Navigation } from 'shared/components';
import { useMobile } from 'shared/hooks';

interface Props extends PropsWithChildren {
  contentClassName?: string;
  containerClassName?: string;
  containerId?: string;
  headerClassName?: string;
  navButtons?: NavigationButtons[];
  footerComponent?: React.FC;
  proposal?: Proposal;
  proposalCompanyLogo?: string | null;
  proposalCompanyName?: string;
  organizationId?: string;
  isToCVisible?: boolean;
  inlineStyles?: React.CSSProperties;
}

export const PreviewLayout: React.FC<Props> = ({
  contentClassName,
  containerClassName = 'container',
  containerId,
  proposalCompanyLogo,
  proposalCompanyName,
  headerClassName,
  organizationId,
  navButtons,
  proposal,
  isToCVisible,
  footerComponent: FooterComponent,
  inlineStyles,
  children,
}) => {
  const isMobile = useMobile();
  const { formatMessage } = useIntl();
  const { view, toggleView } = useProposalSummary();

  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const userOrganizationId = useOrganizationIdSelector();
  const { hasPermissions } = useProposalPermissions(organizationId);
  const hasTierOne = useRecoilValue(subscriptionSelectors.hasTierOne);
  const userHasResponded = useMemo(
    () => proposal?.acceptanceStatus === 'accepted' || 'rejected',
    [proposal?.acceptanceStatus],
  );
  const coverImg = useMemo(
    () =>
      proposal?.companyInfo?.companyImage || proposalCompanyLogo || undefined,
    [proposal?.companyInfo?.companyImage, proposalCompanyLogo],
  );
  const companyName = useMemo(
    () => proposal?.companyInfo?.name || proposalCompanyName || '',
    [proposal?.companyInfo?.name, proposalCompanyName],
  );

  const rootUrlId = useMemo(
    () =>
      hasPermissions || userOrganizationId !== 'public'
        ? 'routes.overview'
        : 'routes.home',
    [hasPermissions, userOrganizationId],
  );

  function renderLogo() {
    if (!proposal && !coverImg) {
      return (
        <Link
          to={formatMessage({
            id: rootUrlId,
          })}
          rel={
            hasPermissions || userOrganizationId !== 'public'
              ? ''
              : 'noopener noreferrer'
          }
          className="header__no__proposal__header"
        >
          <img src={logoImage} alt="LogoImage" className="logo__image" />
          <img
            src={logoImageSmall}
            alt="LogoImageSmall"
            className="d--mobile--only"
          />
        </Link>
      );
    }

    if (isToCVisible && hasTierOne && coverImg) {
      return (
        <Link
          to={formatMessage({
            id: rootUrlId,
          })}
          rel={
            hasPermissions || userOrganizationId !== 'public'
              ? ''
              : 'noopener noreferrer'
          }
          data-cy="logo-link-header"
        >
          <img
            src={coverImg}
            alt="Company Logo"
            className="header__company__image"
          />
        </Link>
      );
    }

    return (
      <Link
        to={formatMessage({
          id: rootUrlId,
        })}
        rel={
          hasPermissions || userOrganizationId !== 'public'
            ? ''
            : 'noopener noreferrer'
        }
        data-cy="logo-link-header"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25, ease: 'easeInOut' }}
          className="header__no__company__image"
        >
          <span>{companyName[0]}</span>
        </motion.div>
      </Link>
    );
  }

  return (
    <>
      <motion.div
        variants={
          !isMobile
            ? {
                open: {
                  marginLeft: '302px',
                  transition: { duration: 0.25, ease: 'easeInOut' },
                },
                closed: {
                  marginLeft: '0',
                  transition: { duration: 0.25, ease: 'easeInOut' },
                },
              }
            : {}
        }
        animate={isSummaryOpen && !isMobile ? 'open' : 'closed'}
        id={containerId}
        className={`${containerClassName || ''} container__content`}
      >
        <header className={`${headerClassName || ''} header header--visible`}>
          <div className="header__branding">
            {(hasPermissions || hasTierOne) && isToCVisible && (
              <FeatureFlagWrapper flag="TABLE-OF-CONTENTS">
                <ProposalSummaryDrawer
                  view={view}
                  proposal={proposal}
                  isPreview
                  isSummaryOpen={isSummaryOpen}
                  isSubscribed={hasTierOne}
                  onSetSummaryOpen={setIsSummaryOpen}
                  toggleView={toggleView}
                />
              </FeatureFlagWrapper>
            )}
            {renderLogo()}
          </div>
          {userHasResponded && (
            <div className="d--desktop--only">
              {proposal?.acceptanceStatus === 'rejected' && (
                <div className="f f--center f--align-center">
                  <img
                    src={failedIcon}
                    alt="IconFailed"
                    style={{ width: 18, height: 18 }}
                    className="proposal-acceptance-controller__status__icon"
                  />
                  <p
                    className="text--xs t-bold t-text-1"
                    data-cy="proposal-acceptance-status-heading-accepted"
                  >
                    Proposal declined
                  </p>
                </div>
              )}
              {proposal?.acceptanceStatus === 'accepted' &&
                !proposal?.isInvoice && (
                  <div className="f f--one f--align-center proposal-acceptance-controller__status">
                    <img
                      src={successIcon}
                      alt="IconSuccess"
                      style={{ width: 18, height: 18 }}
                      className="proposal-acceptance-controller__status__icon"
                    />
                    <p
                      className="text--xs t-bold t-text-1"
                      data-cy="proposal-acceptance-status-heading-accepted"
                    >
                      Proposal accepted
                    </p>
                  </div>
                )}
            </div>
          )}
          <div className="header__nav">
            {userHasResponded && (
              <div className="d--mobile--only s-right--12">
                {proposal?.acceptanceStatus === 'rejected' &&
                  !proposal?.isInvoice && (
                    <div className="f f--center f--align-center">
                      <img
                        src={failedIcon}
                        alt="IconFailed"
                        className="s-right--12"
                      />
                      <p
                        className="text--sm t-bold t-text-1"
                        data-cy="proposal-acceptance-status-heading-accepted"
                      >
                        Proposal declined
                      </p>
                    </div>
                  )}
                {proposal?.acceptanceStatus === 'accepted' &&
                  !proposal?.isInvoice && (
                    <div className="f f--center f--align-center">
                      <img
                        src={successIcon}
                        alt="IconSuccess"
                        className="s-right--12"
                        style={{ width: 12, height: 12 }}
                      />
                      <p
                        className="text--sm t-bold t-text-1"
                        data-cy="proposal-acceptance-status-heading-accepted"
                      >
                        Proposal accepted
                      </p>
                    </div>
                  )}
              </div>
            )}
            <Navigation buttons={navButtons} proposal={proposal} />
          </div>
        </header>
        <main
          className={`container__main-content ${contentClassName || ''}`}
          style={inlineStyles}
        >
          <>{children}</>
        </main>
        {FooterComponent && <FooterComponent />}
      </motion.div>
      <div id="global-modal" />
    </>
  );
};
