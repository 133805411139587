import { DualPrice, Price, SinglePrice, formatPrice } from 'modules/proposals';
import React from 'react';

interface Props {
  currency: string;
  totalAmount: number;
  exchangeRate?: number;
  dualCurrency?: string;
  ISOCountry?: string;
}

export const TotalPublicProposalPrice: React.FC<Props> = ({
  currency,
  totalAmount,
  exchangeRate,
  dualCurrency = 'eur',
  ISOCountry,
}) => (
  <Price
    currency={currency}
    dualCurrency={dualCurrency}
    customExchangeRate={exchangeRate}
    dualPriceElement={
      <DualPrice>
        <h3 className="text--xxl__responsive">
          <strong>{formatPrice(totalAmount, currency, ISOCountry)}</strong>
        </h3>
        {exchangeRate && (
          <p className="o-60 t-eta">
            {formatPrice(totalAmount * exchangeRate, dualCurrency, ISOCountry)}
          </p>
        )}
      </DualPrice>
    }
    singlePriceElement={
      <SinglePrice>
        <h3 className="text--xxl__responsive">
          <strong>{formatPrice(totalAmount, currency, ISOCountry)}</strong>
        </h3>
      </SinglePrice>
    }
  />
);
