import expiredIcon from 'assets/icons/icon-expired-warning-16.svg';
import { useProposalExpiredSelector } from 'modules/proposals';
import { subscriptionSelectors } from 'modules/subscription';
import React, { useCallback, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { FieldWrapper } from 'shared/components';

export const ValidUntilDateTimePicker: React.FC = () => {
  const { formatMessage } = useIntl();
  const flatpickrRef = useRef(null);

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<PublishStatusAware<Proposal>>();
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const isExpiredNotificationVisible = useProposalExpiredSelector(
    getValues(),
    isTierOne,
  );

  const handleDateChange = useCallback(
    (
      field: ControllerRenderProps<
        PublishStatusAware<Proposal>,
        'validUntilDateTime'
      >,
    ) =>
      ([selectedDate]: Date[]) => {
        field.onChange(+selectedDate);
      },
    [],
  );

  const openDatePicker = () => {
    if (flatpickrRef.current) {
      (flatpickrRef.current as Flatpickr).flatpickr.open();
    }
  };

  return (
    <FieldWrapper
      name="validUntilDateTime"
      labelId={
        getValues('isInvoice')
          ? 'datepickers.due-date.label'
          : 'datepickers.validity-date.label'
      }
      isRequired
      helpTextId={
        isExpiredNotificationVisible
          ? 'datepickers.validity-date.expired'
          : undefined
      }
      helpTextClassName={
        isExpiredNotificationVisible
          ? 'general-info__date__expired text--xsm'
          : undefined
      }
      containerClassName="u-pos--rel"
      errors={errors}
    >
      <Controller
        name="validUntilDateTime"
        control={control}
        rules={{
          required: formatMessage({
            id: 'datepickers.validity-date.required',
          }),
        }}
        defaultValue={getValues('validUntilDateTime')}
        render={({ field }) => (
          <>
            <Flatpickr
              {...field}
              ref={flatpickrRef}
              data-enable-time
              options={{
                disableMobile: true,
                dateFormat: 'Y-m-d H:i',
              }}
              className={`input input__med input__box ${
                isExpiredNotificationVisible
                  ? 'general-info__date__expired__input'
                  : ''
              } ${errors.validUntilDateTime ? 'input__box__error' : ''}`}
              placeholder={formatMessage({
                id: 'datepickers.validity-date.placeholder',
              })}
              onChange={handleDateChange(field)}
              data-cy="builder-valid-until-picker"
            />
            {isExpiredNotificationVisible && (
              <img
                src={expiredIcon}
                onClick={openDatePicker}
                className="btn__icon general-info__date__expired__icon"
                alt="ExpiredIcon"
              />
            )}
          </>
        )}
      />
    </FieldWrapper>
  );
};
